<template>
  <div class="login-container">
    <div class="login-conent">
      <!-- <div class="login-icon">3CIRCLES</div> -->
      <div class="login-title">帮客智能客服系统</div>
      <div>
        <div class="form-item">
          <img
            class="form-icon"
            src="../../assets/images/user_icon.png"
            alt=""
          />
          <input
            v-model="username"
            maxlength="20"
            class="login-input"
            type="text"
            placeholder="请输入用户名"
          />
        </div>
        <div class="error" v-if="!username">请输入用户名！</div>
        <div class="error" v-if="username"></div>
        <div class="form-item">
          <img
            class="form-icon"
            src="../../assets/images/password_icon.png"
            alt=""
          />
          <input
            v-model="password"
            maxlength="20"
            class="login-input"
            type="password"
            placeholder="请输入密码"
          />
        </div>
        <div class="error" v-if="!password">请输入密码！</div>
        <div class="error" v-if="password"></div>
      </div>
      <div class="login-btn" @click="login">登录</div>
      <div class="login-tip">有问题联系管理员</div>
    </div>
  </div>
</template>
<script>
import { login } from "../../service/common.js";
import { Config } from "../../utils/index.js";
export default {
  components: {},
  data() {
    return {
      fromRoute: this.$route.query.fromRoute,
      username: "",
      password: "",
      Authorization: ""//
    };
  },
  destroyed() {
    window.removeEventListener("keydown", this.loginPress, false);
  },
  mounted() {
    //绑定事件
    window.addEventListener("keydown", this.loginPress);
  },
  methods: {
    loginPress(e) {
      if (e.keyCode == 13) {
        this.login();
      }
    },
    async login() {
      let params = {
        username: this.username,
        password: this.password,
      };
      let loginData = await login(params);
      if (loginData.code == 200) {
        if (loginData.data && loginData.data.token) {
          this.Authorization = loginData.data.tokenHead + " " + loginData.data.token;
          this.$sto.set(Config.store.token, this.Authorization);
        }
        if (this.fromRoute) {
          window.location.href = this.fromRoute
        } else {
          if(loginData.data.haveCompany == 1){
            this.$router.push({ path: "/create-com",query:{phone: this.username} });
          } else if(loginData.data.haveCompany == 2){
            // 显示公司档案
            // window.localStorage.setItem('haveCom',"2")
            this.$sto.set(Config.constants.haveCompany, "2");
            this.$router.push({ path: "/index" });
          }
          if(loginData.data.status == "0"){ //跳转到平台首页
            // yxdev2 dev2
            if(window.location.origin.indexOf("dev2") != -1){
              this.open('dev2')
            }else if(window.location.origin.indexOf("dev") != -1){
              this.open('dev')
            }else {
              this.open('local')
            }
          }else { //公司首页
            this.$router.push({ path: "/index" });
          }
        }
      }
    },
    open(type) {
        this.$confirm('当前账号为平台账号, 将为您跳转到平台系统', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: true
        }).then(() => {
          if(type == 'dev2'){
            window.open(`https://yx2dev.fwwb.vip/index?auth=${this.Authorization}`)
          }else if(type == 'dev'){
            window.open(`https://yx2.fwwb.vip/index?auth=${this.Authorization}`)
          }else if(type == 'local'){
            window.open(`http://localhost:8080/index?auth=${this.Authorization}`)
          }
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消跳转'
          });          
        });
      }
  },
};
</script>
<style lang="less" scoped>
.login-container {
  width: 100vw;
  height: 100vh;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  background-color: #f5f7ff;
  background-image: url("../../assets/images/login_bg.png");
  background-size: 700px 400px;
  background-position: 230px center;
  background-repeat: no-repeat;
  flex-direction: row-reverse;
  box-sizing: border-box;
  padding-right: 200px;
  .login-tip {
    font-size: 12px;
    color: #cac1c1;
    margin-top: 28px;
  }
  .login-btn {
    width: 100%;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    color: #fff;
    background-color: #4a76ff;
    background-size: 100% 100%;
    border-radius: 6px;
    margin-top: 38px;
    cursor: pointer;
  }
  .error {
    font-size: 14px;
    color: #f00;
    text-align: left;
    margin-bottom: 16px;
  }
  .form-item {
    position: relative;
    margin-bottom: 12px;
    .form-icon {
      // width: 16px;
      height: 18px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 12px;
      margin: auto;
    }
    .login-input {
      padding: 4px 11px 4px 40px;
      width: 296px;
      height: 48px;
      box-sizing: border-box;
      // margin-top: 6px;
      border: none;
      outline: 0;
      font-size: 14px;
      border-radius: 8px;
      background: #e8eeff;
      &::-webkit-input-placeholder {
        color: rgba(118, 118, 118, 0.65);
      }
    }
  }

  .login-conent {
    width: 400px;
    height: 460px;
    border-radius: 16px;
    background: #fff;
    padding: 30px 52px;
    box-sizing: border-box;
    position: relative;
    box-shadow: 0px 6px 32px 0px rgba(122, 156, 255, 0.6);
    .login-icon {
      font-weight: 100;
      color: #4a76ff;
      font-size: 28px;
      width: 200px;
      height: 70px;
      line-height: 70px;
      border-radius: 35px;
      text-align: center;
      background: #ffffff;
      box-shadow: 0px 3px 4px 0px rgba(122, 167, 255, 0.29);
      position: absolute;
      top: -40px;
      left: 0;
      right: 0;
      margin: auto;
    }
    .login-title {
      height: 80px;
      line-height: 80px;
      text-align: center;
      margin-top: 12px;
      font-size: 26px;
      font-weight: 700;
      color: #4a76ff;
    }
  }
  
}
</style>
<style lang="less">
 .el-message-box__wrapper{
  .el-message-box{
    width: 600px !important;
    height: 16%;
  }
}
</style>